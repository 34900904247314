/* eslint-disable jsx-a11y/img-redundant-alt */
export default function Contact() {
  return (
    <section className="hero-style-1 contact-hero bg-white">
      <div className="hero-area overflow-hidden position-relative zindex-1 bg-primary-gradient pt-120">
        <img
          src="assets/img/shapes/circle.svg"
          alt="circle shape"
          className="circle-shape position-absolute"
        />
        <img
          src="assets/img/shapes/contact-hero-left.png"
          alt="circle"
          className="left-bottom position-absolute"
        />
        <div className="container my-container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="hero1-content-wrap">
                <h1 className="display-font mt-4">
                  Hãy Liên Hệ Với Chúng Tôi
                </h1>
                <p className="mt-4">
                  Thời gian là tiền bạc! Chúng tôi xem trọng khách hàng và thời gian của mọi người, vì vậy,
                  đội ngũ của chúng tôi luôn làm tốt nhất có thể để giúp bạn giải quyết vấn đề nhanh chóng!
                </p>
                <a href="tel:0867629622" className="template-btn primary-btn rounded-pill mt-4">
                  <span className="me-2">
                    <i className="fa-solid fa-comments" />
                  </span>
                  Liên Hệ Ngay
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-right">
                <img src="assets/img/0001.svg" alt="hero image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hm-contact-info promo-area position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="hm-ct-info-wrapper rounded">
                <span className="icon-wrapper rounded-circle d-inline-flex align-items-center justify-content-center">
                  <i className="fa-solid fa-envelope"></i>
                </span>
                <div className="info-content mt-4">
                  <h4>Email</h4>
                  <span>admin@vpsproxy247.com</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="hm-ct-info-wrapper rounded">
                <span className="icon-wrapper rounded-circle d-inline-flex align-items-center justify-content-center">
                  <i className="fa-solid fa-at" />
                </span>
                <div className="info-content mt-4">
                  <h4>Admin</h4>
                  Telegram: <a href="https://t.me/vpsproxychinhhang">vpsproxychinhhang</a>{' / '}
                  Zalo: <a href="tel:0867629622">(+84) 867629622</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="hm-ct-info-wrapper rounded">
                <span className="icon-wrapper rounded-circle d-inline-flex align-items-center justify-content-center">
                  <i className="fa-solid fa-house" />
                </span>
                <div className="info-content mt-4">
                  <h4>Địa chỉ</h4>
                  <span>HUD2, tổ 5, phường trần Hưng Đạo, TP. Thái Bình</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}
