/* eslint-disable jsx-a11y/iframe-has-title */

import URL from "constant/url";
import { useNavigate } from "react-router-dom";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Footer() {
  const navigate = useNavigate();
  return (
    <footer className="footer host-web-footer pt-100">
      <div className="footer-top pb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget footer-widget-1">
                <a href={URL.Home} className="footer-logo">
                  <img
                    src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo-r.png`}
                    width={160}
                    alt="logo"
                  />
                </a>
                <p className="text-white mt-30">
                  Đơn vị hàng đầu cung cấp các giải pháp hạ tầng IT, <br /> điện
                  toán đám mây.
                </p>
                <div className="footer-social mt-5">
                  <h5 className="ft-subtitle text-white position-relative d-inline-block">
                    Theo dõi chúng tôi
                  </h5>
                  <ul className="social-nav mt-3">
                    <li>
                      <a
                        href="https://www.facebook.com/quocdat.vps"
                        target="_blank"
                        rel="noreferrer"
                        className="rounded-circle overflow-hidden position-relative d-flex align-items-center justify-content-center text-white"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://t.me/vpsproxychinhhangs"
                        target="_blank"
                        rel="noreferrer"
                        className="rounded-circle overflow-hidden position-relative d-flex align-items-center justify-content-center text-white"
                      >
                        <i className="fab fa-telegram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="footer-widget footer-widget-2 mt-2">
                <h5 className="text-white position-relative mb-5 widget-title">
                  Sản phẩm
                </h5>
                <ul className="footer-nav">
                  <li>
                    <a href="" onClick={() => navigate(URL.ListServerVN)}>VPS</a>
                  </li>
                  <li>
                    <a href="" onClick={() => navigate(URL.ListDedicatedServerUS)}>Dedicated Server</a>
                  </li>
                  <li>
                    <a href="" onClick={() => navigate(URL.ListProxyVN)}>Proxy</a>
                  </li>
                  <li>
                    <a href="" onClick={() => navigate(URL.List922S5Proxy)}>S5 Proxy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="footer-widget footer-widget-3 mt-2">
                <h5 className="text-white position-relative mb-5 widget-title">
                  Thông tin
                </h5>
                <ul className="footer-nav">
                  <li>
                    <a href="" onClick={() => navigate(URL.Agreement)}>Điều khoản</a>
                  </li>
                  <li>
                    <a href="" onClick={() => navigate(URL.Guide)}>Hướng dẫn</a>
                  </li>
                  <li>
                    <a href="" onClick={() => navigate(URL.SLA)}>SLA</a>
                  </li>
                  <li>
                    <a href="" onClick={() => navigate(URL.Contact)}>Liên hệ</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-8">
              <div className="footer-widget footer-sb-widget mt-2">
                <h5 className="text-white position-relative mb-5 widget-title">
                  Địa chỉ
                </h5>
                <p className="text-white">
                  <i
                    className="fa-solid fa-location-dot"
                    style={{ marginRight: "8px" }}
                  />
                  HUD2, tổ 5, phường trần Hưng Đạo, TP. Thái Bình
                </p>
                <p className="text-white">
                  <i
                    className="fa-solid fa-phone-volume"
                    style={{ marginRight: "8px" }}
                  ></i>
                  (+84) 867629622
                </p>
                <p className="text-white">
                  <i
                    className="fa-regular fa-envelope"
                    style={{ marginRight: "8px" }}
                  ></i>
                  admin@vpsproxy247.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="copyright-txt text-white">
                <p className="mb-0">
                  Copyright 2024 VPSPROXY247. All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
